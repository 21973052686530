import React, { useEffect } from "react";
import ProductItem from "./ProductItem";
import useFetch from "../hooks/useFetch";
import { CircularProgress } from "@mui/material";

const ProductList = ({ data }) => {
  return (
    <div>
      {data && data.length === 0 ? (
        <div className="w-full h-screen flex justify-center items-center text-4xl fade-up ">
          Aucune Paire
        </div>
      ) : (
        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 mb-8  lg:mx-24 pb-24">
          {data.map((item) => (
            <div className="flex justify-center" key={item.id}>
              <ProductItem key={item.id} product={item} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductList;
