import React, { useState, useEffect } from "react";
import BrandForm from "./BrandForm";
import Button from "@mui/material/Button";
import { makeRequest } from "../makeRequest";

const BrandManager = () => {
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);

  useEffect(() => {
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await makeRequest.get("/brands/");
      setBrands(response.data);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const handleEditClick = (brand) => {
    setSelectedBrand(brand);
  };

  const handleSuccess = () => {
    fetchBrands();
    setSelectedBrand(null);
  };

  return (
    <div className="mt-32 w-full">
      <div className="bg-black w-full my-12 text-white text-right text-5xl p-4 lg:px-20 sm:px-8">
        {" "}
        <p className="fade-right text-nowrap">Brand Manager</p>
      </div>{" "}
      <div className="p-8 w-full flex justify-center items-center flex-col fade-up">
        <BrandForm existingBrand={selectedBrand} onSuccess={handleSuccess} />
        <ul className="mt-6 space-y-2 w-full ">
          {brands.map((brand) => (
            <li
              key={brand._id}
              className={`flex border-b-2 p-4 items-center justify-between`}
            >
              <span className="text-lg">{brand.name}</span>
              {brand._id === selectedBrand?._id ? (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => setSelectedBrand(null)}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleEditClick(brand)}
                >
                  Edit
                </Button>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BrandManager;
