import React from "react";
import Title from "../components/Title";

function Guide() {
  return (
    <div>
      <Title title={"Guide"}></Title>
    </div>
  );
}

export default Guide;
