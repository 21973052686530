import React, { useEffect } from "react";
import Search from "../components/Search";
import Title from "../components/Title";

function Home() {
  return (
    <div className="w-full h-full block m-0 mt-24 bt-24">
      <div className="w-full h-[500px] relative fade-in ">
        <div className="w-screen absolute border-y-[24px] overflow-hidden border-black flex items-center justify-end gap-8 h-full bg-gradient-to-r from-gray-800 to-black px-8">
          <span className="absolute w-screen h-full bg-gradient-to-r from-purple-800  to-black animate-pulse-slow "></span>
          <div className="particle"></div>
          <img
            src="./assets/silhouette.png"
            className=" absolute animate-pulse-slow w-auto lg:left-40 sm-shadow h-full"
          ></img>
          <div className="text-7xl font-extrabold text-white fade-right select-none">
            <h1 className="text-center sm-shadow">No</h1>{" "}
            <h1 className="text-center  sm-shadow">Nego</h1>{" "}
            <h1 className="text-center  sm-shadow">Shoes</h1>
          </div>
          <img
            className="invert brightness-0 h-[10%] fade-right "
            src="./assets/nonego_logo.png"
          ></img>
        </div>
      </div>
      <Title title={"Marchandise"}></Title>
      <Search></Search>
    </div>
  );
}

export default Home;
