import React, { useState } from "react";
import {
  Button,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  TextField,
  FormLabel,
  Box,
} from "@mui/material";

function RequestForm({ product }) {
  const [showForm, setShowForm] = useState(false);
  const [contactMethod, setContactMethod] = useState("");
  const [contactInfo, setContactInfo] = useState("");

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleRadioChange = (event) => {
    setContactMethod(event.target.value);
    setContactInfo(""); // Reset contact info when method changes
  };

  const handleInputChange = (event) => {
    setContactInfo(event.target.value);
  };

  return (
    <Box sx={{ mt: 3 }}>
      {!showForm ? (
        <div>
          {product.disponible ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleButtonClick}
            >
              Faire une demande
            </Button>
          ) : (
            <Button variant="contained" disabled color="error">
              Non dispo
            </Button>
          )}
        </div>
      ) : (
        <Box>
          <form onSubmit={handleSubmit}>
            <FormControl component="fieldset">
              <FormLabel component="legend" color="secondary">
                Comment souhaitez-vous être contacté ?
              </FormLabel>
              <RadioGroup
                name="contactMethod"
                value={contactMethod}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="phone"
                  control={<Radio color="secondary" />}
                  label="Téléphone"
                />
                <FormControlLabel
                  value="email"
                  control={<Radio color="secondary" />}
                  label="Email"
                />
                <FormControlLabel
                  value="txt"
                  control={<Radio color="secondary" />}
                  label="Texte (SMS)"
                />
              </RadioGroup>

              {contactMethod && (
                <TextField
                  label={
                    contactMethod === "phone"
                      ? "Numéro de téléphone"
                      : contactMethod === "email"
                      ? "Adresse email"
                      : "Numéro pour les textos"
                  }
                  value={contactInfo}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
              )}
              <Button
                disabled={contactInfo === ""}
                variant="contained"
                color="secondary"
                type="submit"
              >
                Faire Ma Demande!
              </Button>
            </FormControl>
          </form>
        </Box>
      )}
    </Box>
  );
}

export default RequestForm;
